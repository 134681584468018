<template>
<!-- 财务管理 -->
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-select
            placeholder="选择门店"
            class="form-input-150"
            filterable
            v-model="searchObj.shopId"
            :disabled="disabled"
          >
            <el-option
              v-for="(item, index) in storeList"
              :key="index"
              :label="item.storeName"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            placeholder="选择收入来源"
            class="form-input-150 ml-10"
            clearable
            v-model="searchObj.type"
          >
            <el-option
              v-for="(item, index) in income_type"
              :key="index"
              :label="item.value"
              :value="item.type"
            ></el-option>
          </el-select>
             <el-select
            placeholder="选择流水状态"
            class="form-input-150 ml-10"
            clearable
            v-model="searchObj.orderType"
          >
            <el-option
              v-for="(item, index) in order_type"
              :key="index"
              :label="item.value"
              :value="item.type"
            ></el-option>
          </el-select>
          <el-date-picker
            v-model="searchObj.date"
            class="ml-10"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="pickDate"
          ></el-date-picker>
          <el-radio-group
            v-model="searchObj.queryTime"
            size="small"
            class="ml-10"
            @change="searchObj.date=[]"
          >
            <el-radio-button label="昨天"  :value="1"></el-radio-button>
            <el-radio-button label="近7天" :value="2"></el-radio-button>
            <el-radio-button label="近30天" :value="3"></el-radio-button>
          </el-radio-group>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-10 form-button-w70"
            @click="search"
          >查询</el-button>
          <el-button icon="el-icon-refresh" class="ml-10 form-button-w70" @click="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column label="收入流水号" prop="serialNumber"></el-table-column>
            <el-table-column label="门店" prop="shopName"></el-table-column>
            <el-table-column label="收入（元）" prop="amount"></el-table-column>
            <el-table-column label="收入来源">
              <template slot-scope="scope">{{scope.row.type==1?'百货订单':scope.row.type==2?'停车缴费':scope.row.type==3?'商超订单':'餐饮订单'}}</template>
            </el-table-column>
             <el-table-column label="流水类型" prop="orderType">
               <template slot-scope="scope">{{scope.row.orderType==1?'收入流水号':'退款流水号'}}</template>
             </el-table-column>
            <el-table-column label="入账时间" prop="payTime"></el-table-column>
            <el-table-column label="用户" prop="userName"></el-table-column>
            <el-table-column label="手机号" prop="userPhone"></el-table-column>
            <el-table-column label="支付方式" prop="payType"></el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @changePage="initData"
            @syncPageData="syncPageData"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { getStoreList } from "@/api/online_department_store/commodity_manage";
import { getFinancialDetails } from "@/api/transactions/financials/financial";
export default {
  components: {
    Pagination
  },
  data() {
    return {
      //搜索栏相关数据
      searchObj: {
        date: [],
        shopId: "",
        type: "",
        queryTime: "",
        orderType:""
      },
      // 收入来源与门店列表
      income_type: [
        { type: "1", value: "百货订单" },
        { type: "2", value: "停车缴费" },
        { type: '3', value: '商超订单' },
        { type: '4', value: '餐饮订单' }
        // { type: '5', value: '营销活动' }
      ],
      order_type:[  //流水状态  1收入 2退款
        { type: "1", value: "收入流水号" },
        { type: "2", value: "退款流水号" }
      ],
      storeList: [],
      // 会员表格数据内容
      tableData: [],
      //用于分页
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      disabled: false,
      beginTime: undefined,
      endTime: undefined
    };
  },
  mounted() {
       //获取门店列表
    getStoreList().then(res => {
          this.storeList = res.data.body.list;
        })
        .catch(err => {
          // console.log(err);
        });
    this.initData();
  },
  methods: {
    initData() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      if(this.$store.getters.storeId){
        this.disabled = true
        this.searchObj.shopId = this.$store.getters.storeId
      }
      data = Object.assign(data, this.searchObj);
      if(data.queryTime) {
          switch (data.queryTime) {
        case "昨天":
          data.queryTime = 1;
          break;
        case "近7天":
         data.queryTime = 2;
          break;
        case "近30天":
         data.queryTime = 3;
          break;
        default:  
          break;
      }
      }
      if(this.searchObj.date instanceof Array){
        data.beginTime = this.searchObj.date[0];
        data.endTime = this.searchObj.date[1];
      }
      // console.log("data",data)
      data = JSON.parse(JSON.stringify(data));
      delete data.date;
     getFinancialDetails(data).then(res => {
        this.tableData = res.data.body.list;
        this.total = res.data.body.total;
      });
    },
    search() {
      this.initData();
    },
    reset() {
      this.searchObj = {
        date: [],
        shopId: "",
        type: "",
        queryTime: "",
        orderType:""
      };
      this.endTime = ''
      this.beginTime = ''
      this.initData();
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    pickDate(e) {
      this.searchObj.queryTime = "";
    }
  }
};
</script>
<style lang="scss" scoped></style>
